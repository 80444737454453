import { useHasProperty, usePersistedState } from "@/hooks";
import useRole from "@/hooks/useRole";
import { DataGrid } from "@/includes";
import { RootState } from "@/services/redux/store";
import { ROLE_ENUM } from "@/utils/enums/roles";
import { EdgeType } from "@/utils/types/requests";
import { useQuery } from "@apollo/client";
import { Box, Collapse, Stack, useTheme } from "@mui/material";
import { isEmpty } from "lodash";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import DeleteExperienceDialog from "./components/DeleteExperienceDialog";
import DuplicateInOtherSiteDialog from "./components/DuplicateOnOtherSiteDialog";
import { GET_LABELS } from "./components/LabelsAutocomplete/requests";
import NoRowsOverlay from "./components/NoRowsOverlay";
import Toolbar from "./components/Toolbar";
import { REQUIRED_INTEGRATIONS } from "./enums";
import { ExperienceListProps, LabelType } from "./types";
import useExperienceList from "./useExperienceList";

const ExperienceList = ({ searchType }: ExperienceListProps) => {
  const { t }: i18translateType = useTranslation();
  const theme = useTheme();
  const siteId = useSelector((root: RootState) => root.site.siteId);
  const requiredIntegrations = REQUIRED_INTEGRATIONS[searchType];
  const [hasRowClick, setHasRowClick] = React.useState<boolean>(false);

  const devMode = useSelector(
    (state: RootState) => state.developerMode.isEnabled
  );
  const { role, isSuperUser } = useRole();
  const { hasAnalytics } = useHasProperty();
  const [searchExp, setSearchExp] = React.useState<string>("");
  const [selectedLabels, setSelectedLabels] = usePersistedState({
    key: `filters-labels-${siteId}`,
    initialValue: [],
  });
  const [openDuplicateOnOtherSite, setOpenDuplicateOnOtherSite] =
    React.useState<boolean>(false);
  const [experienceDuplicationOnOtherSite, setExperienceDuplicateOnOtherSite] =
    React.useState<Dic<any>>({});
  const handleOpenDuplicateOnOtherSite = (
    experienceId: string,
    experienceName: string
  ) => {
    setExperienceDuplicateOnOtherSite({
      id: experienceId,
      name: experienceName,
    });
    setOpenDuplicateOnOtherSite(true);
  };
  const handleCloseDuplicateOnOtherSite = () => {
    setExperienceDuplicateOnOtherSite({});
    setOpenDuplicateOnOtherSite(false);
  };

  const handleChangeSelectedLabel = (id: number) => {
    if (selectedLabels.includes(id)) {
      setSelectedLabels(
        selectedLabels.filter((idLabel: number) => idLabel !== id)
      );
    } else {
      setSelectedLabels([...selectedLabels, id]);
    }
  };

  React.useEffect(() => {
    if (isSuperUser || role !== ROLE_ENUM.READER) {
      setHasRowClick(true);
    } else {
      setHasRowClick(false);
    }
  }, [role, isSuperUser]);

  const [labels, setLabels] = React.useState<Array<LabelType>>([]);

  const { refetch: refetchLabels } = useQuery(GET_LABELS, {
    variables: { siteId, first: 100 },
    skip: isEmpty(siteId),
    onCompleted: (data: Dic<any>) => {
      setLabels(
        data?.labels?.edges?.map((edge: EdgeType) => edge.node) ?? [-1]
      );
      if (selectedLabels.length === 0)
        setSelectedLabels([
          -1,
          ...(data?.labels?.edges?.map((edge: EdgeType) => edge.node.id) ?? [
            -1,
          ]),
        ]);
    },
  });

  const {
    handleClose,
    experienceToDelete,
    refetch,
    show,
    newRows,
    filteredColumns,
    handleEdit,
    canCreate,
    gettingExperiences,
    handleReport,
  } = useExperienceList(
    searchType,
    hasAnalytics,
    searchExp,
    handleOpenDuplicateOnOtherSite,
    labels,
    refetchLabels,
    selectedLabels
  );

  React.useEffect(() => {
    refetch();
  }, []);

  const handleProcessRowUpdate = () => {
    refetchLabels();
    refetch();
  };

  return (
    <>
      <DeleteExperienceDialog
        handleClose={handleClose}
        open={!!experienceToDelete.id}
        experienceId={experienceToDelete.id}
        experienceName={experienceToDelete.name}
        updateExperiences={refetch}
      />
      <DuplicateInOtherSiteDialog
        open={openDuplicateOnOtherSite}
        handleClose={handleCloseDuplicateOnOtherSite}
        experienceId={experienceDuplicationOnOtherSite?.id}
        experienceName={experienceDuplicationOnOtherSite?.name}
      />
      <Box
        sx={{
          padding: 0,
          borderRadius: 3,
          maxWidth: "99%",
          "& .MuiDataGrid-row.has-row-click:hover": {
            background: `${theme.customPalette.others.background} !important`,
            cursor: "pointer",
          },
        }}
      >
        <Stack spacing={1}>
          <Collapse in={show}>
            <DataGrid
              localeText={{
                footerTotalRows: t("total_rows"),
              }}
              onCellClick={(params) => {
                if (params.field != "actions" && params.field != "labels") {
                  if (hasAnalytics && params.row.canReport) {
                    handleReport(params.row.id);
                  } else if (params.row.canEdit && hasRowClick) {
                    handleEdit(params.row.id);
                  }
                }
              }}
              getRowClassName={(params) =>
                (hasRowClick || params.row.canReport) && `has-row-click`
              }
              initialState={{
                pinnedColumns: { right: ["actions"] },
              }}
              currentPage={0}
              pageSize={newRows.length}
              autoHeight
              rows={newRows}
              columns={filteredColumns}
              loading={gettingExperiences}
              componentsProps={{
                noRowsOverlay: {
                  canCreate,
                  handleEdit,
                  refetch,
                  requiredIntegrations,
                  searchType,
                },
                toolbar: {
                  search: searchExp,
                  setSearch: setSearchExp,
                  labels,
                  selectedLabels,
                  handleChangeSelectedLabel,
                },
              }}
              components={{
                NoRowsOverlay,
                Toolbar,
              }}
              paginationType="server"
              pagination={false}
              processRowUpdate={handleProcessRowUpdate}
              experimentalFeatures={{ newEditingApi: true }} // Enable new editing API
            />
          </Collapse>
        </Stack>
      </Box>
    </>
  );
};

export default ExperienceList;
