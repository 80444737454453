import { openDrawer } from "@actions";
import { useViewport } from "@hooks";
import MenuIcon from "@mui/icons-material/Menu";
import { useTheme } from "@mui/material/styles";
import { RootState } from "@store";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
  Avatar,
  Box,
  ClickAwayListener,
  DeveloperModeToggle,
  IconButton,
  SiteSelector,
  Stack,
  Tooltip,
} from "../../../../../includes";
import { DesktopWrapper, MobileWrapper } from "../../../../Wrappers";
import HasCatalogWrapper from "../../../HasCatalogWrapper/HasCatalogWrapper";
import SuperAdmin from "../../../SuperAdminWrapper";
import AvatarPopover from "../AvatarPopover";
import { HEIGHT_TOP_BAR } from "../../enums";
import TagTooltip from "../TagTooltip";
import ArrowBack from "@mui/icons-material/ArrowBack";
import Text from "@/components/_Atoms/Text";
import { fontWeight } from "@mui/system";

const TopBar: React.FC = () => {
  const theme = useTheme();
  const { t }: i18translateType = useTranslation();

  const dispatch = useDispatch();
  const hasBuilder = useSelector(
    (state: RootState) => state.site.hasAlgorithmBuilder
  );
  const user = useSelector((state: RootState) => state.login.user);
  const siteId = useSelector((state: RootState) => state.site.siteId);
  const accountId = useSelector((state: RootState) => state.account.accountId);
  const history = useHistory();
  const backHome = () => {
    history.push(
      `/${accountId}/${siteId}/${hasBuilder ? "recommendations/site" : "home"}`
    );
  };

  const { profilePicture } = user;

  const { email } = user;

  const { isMobile } = useViewport();
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (anchorEl) {
      handleClose();
    } else handleOpen(event);
  };

  const handleOpenDrawer = () => dispatch(openDrawer());
  return (
    <Box
      sx={{
        position: "relative",
        boxSizing: "border-box",
        height: HEIGHT_TOP_BAR,
        width: "100%",
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          width: 1,
          height: HEIGHT_TOP_BAR,
          position: "fixed",
          zIndex: 1300,
          backgroundColor: theme.customPalette.others.layer_1,
          boxShadow: "rgba(0, 0, 0, 0.06) 2px 2px 16px 0px",
          top: 0,
          transition: "width 0.4s ease-in-out",
        }}
        spacing={2}
      >
        <Stack
          direction="row"
          sx={{ width: "100%" }}
          justifyContent={"space-between"}
          alignItems="center"
          paddingRight={isMobile ? 0 : 2}
          paddingLeft={isMobile ? 0 : 1}
        >
          <MobileWrapper>
            <IconButton onClick={handleOpenDrawer}>
              <MenuIcon />
            </IconButton>
          </MobileWrapper>
          <DesktopWrapper>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                ml: 1,
                cursor: "pointer",
              }}
              onClick={backHome}
            >
              <Box
                sx={{
                  backgroundColor: theme.customPalette.pink.white,
                  height: "32px",
                  width: "32px",
                  borderRadius: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  mr: 1,
                }}
              >
                <ArrowBack
                  sx={{
                    height: "16px",
                    width: "16px",
                    color: theme.customPalette.pink.dark,
                  }}
                />
              </Box>
              <Text customStyle={{ fontWeight: "bold" }}>
                {t("back_dashboard")}
              </Text>
            </Box>
          </DesktopWrapper>
          <Stack
            direction="row"
            justifyContent="end"
            alignItems="center"
            spacing={2}
          >
            {siteId && (
              <Stack direction="row" alignItems="center" spacing={2}>
                {!hasBuilder && (
                  <DesktopWrapper>
                    <HasCatalogWrapper>
                      <TagTooltip />
                      <Box
                        sx={{
                          height: "17px",
                          width: "2px",
                          backgroundColor: theme.customPalette.others.layer_2,
                        }}
                      />
                    </HasCatalogWrapper>
                  </DesktopWrapper>
                )}
                <SiteSelector />
                <SuperAdmin>
                  <Tooltip title="Dev mode">
                    <div>
                      <DeveloperModeToggle />
                    </div>
                  </Tooltip>
                </SuperAdmin>
              </Stack>
            )}

            <ClickAwayListener onClickAway={handleClose}>
              <>
                <Box onClick={handleClick} sx={{ cursor: "pointer" }}>
                  <Avatar email={email} profilePicture={profilePicture} />
                </Box>

                <AvatarPopover handleClose={handleClose} anchorEl={anchorEl} />
              </>
            </ClickAwayListener>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};

export default TopBar;
